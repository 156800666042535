import { Link } from 'react-location'

import {
  HiMenu,
  HiMenuAlt2,
  HiOutlineAdjustments,
  HiOutlineBell,
  HiOutlineDesktopComputer,
  HiOutlineHome,
  HiOutlineLocationMarker,
  HiOutlineReceiptTax,
  HiOutlineUserGroup
} from 'react-icons/hi'

export function Sidebar() {
  return (
    <div
      className={`flex-col sticky top-0 overflow-hidden h-screen shrink-0 shadow-5 w-72 transition-all`}
    >
      <div className="flex flex-auto flex-col overflow-hidden h-full bg-gray-800 px-3">
        <header className="flex relative top-0">
          <div className="px-4 h-12 md:h-16 w-full flex items-center justify-end">
            <button>
              <HiMenu size={25} color="#dedede" />
            </button>
          </div>
        </header>
        <div className=" px-4 py-3">
          <p className="text-primaryLight font-semibold text-xs">APLICATIVO</p>
          <p className="text-gray-300 text-xs">Gerencie seu aplicativo</p>
        </div>
        <ul className="flex flex-col">
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineHome size={20} className="mr-2" />
              Home
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/categories"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiMenuAlt2 size={20} className="mr-2" />
              Categorias
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/minhas-lojas"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineLocationMarker size={20} className="mr-2" />
              Minhas Lojas
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/notifications"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineBell size={20} className="mr-2" />
              Notificações
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/product-pages"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineDesktopComputer size={20} className="mr-2" />
              Páginas de Produto
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/promotions"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineReceiptTax size={20} className="mr-2" />
              Promoções
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/users"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineUserGroup size={20} className="mr-2" />
              Usuários
            </Link>
          </li>
          <li className="hover:bg-gray-700 hover:pointer rounded mb-2 transition-all">
            <Link
              to="/settings"
              className="text-white text-sm font-medium w-full px-4 py-3 flex"
            >
              <HiOutlineAdjustments size={20} className="mr-2" />
              Configurações
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
